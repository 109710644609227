import React from 'react'
//
import Vignette from '~storybook/vignette'

export const ParagraphVignette = ({ fieldVignetteItems }) => {
  if (!fieldVignetteItems) {
    return null
  }

  const items = fieldVignetteItems
    ?.map((item) => item.entity)
    ?.map(
      ({
        id,
        fieldTitlePrimary,
        fieldTitleSecondary,
        fieldVignetteDescription,
        fieldImagePortrait,
        fieldImageLandscape,
        fieldCta,
        fieldLinks
      }) => {
        return {
          id,
          title: fieldTitlePrimary,
          subtitle: fieldTitleSecondary,
          content: fieldVignetteDescription,
          contentImage: {
            url: fieldImageLandscape?.entity?.thumbnail?.url,
            credits: null
          },
          cta: {
            url: fieldCta?.url?.path,
            label: fieldCta?.title
          },
          links: fieldLinks?.map((link) => {
            return {
              url: link?.url?.path,
              label: link?.title
            }
          }),
          image: {
            url: fieldImagePortrait?.entity?.thumbnail?.url,
            credits: null
          }
        }
      }
    )

  return (
    <section className="bg-white-500 w-full mb-8 relative">
      <Vignette items={items} />
    </section>
  )
}

export default ParagraphVignette
