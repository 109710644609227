import React from 'react'
//
import Card from '~storybook/cards'

const ParagraphCardContact = ({ fieldContact }) => {
  const { entity } = fieldContact

  if (!entity) {
    return null
  }

  const {
    title,
    fieldWebsite,
    fieldEmail,
    fieldAddress,
    fieldPicture,
    fieldExtraInfo,
    fieldTelephoneNumber,
    fieldMobilePhoneNumber,
    fieldSocialmediaTwitter,
    fieldSocialmediaFacebook,
    fieldSocialmediaLinkedin,
    fieldSocialmediaInstagram
  } = entity

  const image = {
    url: fieldPicture?.entity?.thumbnail?.url
  }

  const contact = {
    telephone: fieldTelephoneNumber,
    email: fieldEmail,
    website: fieldWebsite?.url?.path,
    mobile: fieldMobilePhoneNumber
  }

  const { addressLine1, addressLine2, postalCode, locality } = fieldAddress

  let text = ''
  if (addressLine1) {
    text = `${addressLine1}<br />`
  }
  if (addressLine2) {
    text = `${text}${addressLine2}<br />`
  }
  if (postalCode) {
    text = `${text}${postalCode} `
  }
  if (locality) {
    text = `${text}${locality}<br /><br />`
  }
  if (fieldExtraInfo) {
    text = `${text}${fieldExtraInfo}`
  }

  const social = {
    facebook: fieldSocialmediaFacebook?.url?.path,
    twitter: fieldSocialmediaTwitter?.url?.path,
    linkedin: fieldSocialmediaLinkedin?.url?.path,
    instagram: fieldSocialmediaInstagram?.url?.path
  }

  return (
    <Card
      type="card_contact"
      image={image}
      title={title}
      contact={contact}
      text={{ value: text, format: 'html' }}
      social={social}
    />
  )
}

export default ParagraphCardContact
