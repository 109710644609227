import React from 'react'
import tw, {css } from 'twin.macro'
//
import ImageProxy from '~storybook/image'
import Img from 'gatsby-image/withIEPolyfill'
import parse from 'style-to-object';

export const ParagraphPlainImage = ({
  fieldImage,
  fieldCss
}) => {

  const image = {
    url: fieldImage?.entity?.thumbnail?.url,
    width: fieldImage?.entity?.thumbnail?.width,
    height: fieldImage?.entity?.thumbnail?.height
  }

  const CssFromContent = parse(fieldCss)

  return (
    <section className={`c-plain-image-section mb-4`}>
      <img src={image.url} alt={image.alt} width={image.width} height={image.height} style={CssFromContent} />
    </section>
  )
}

export default ParagraphPlainImage
