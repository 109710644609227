import ParagraphText from './text'
import ParagraphHeading from './heading'
import ParagraphCardQuote from './card-quote'
import ParagraphImageContent from './image-content'
import ParagraphForm from './form'
import ParagraphPhotoGallery from './photo-gallery'
import ParagraphVideo from './video'
import ParagraphCardIcon from './card-icon'
import ParagraphAccordion from './accordion'
import ParagraphTimeline from './timeline'
import ParagraphVignette from './vignette'
import ParagraphColumnsRow from './columns-row'
import ParagraphCardImage from './card-image'
import ParagraphCardContact from './card-contact'
import ParagraphTwitterTimeline from './twitter-timeline'
import ParagraphAddressLocationBlock from './address-location-block'
import ParagraphDownloads from './downloads'
import ParagraphMediaGallery from './media-gallery'
import ParagraphSocialMedia from './social-media'
import ParagraphView from './view'
import ParagraphAgenda from './agenda'
import ParagraphIframe from './iframe'
import ParagraphMenu from './menu'
import ParagraphAnchorMenu from './anchor-menu'
import ParagraphPlainImage from './plain-image'
import ParagraphBreadcrumb from './breadcrumb'

const mappedParagraphs = {
  Drupal_ParagraphText: ParagraphText,
  Drupal_ParagraphHeading: ParagraphHeading,
  Drupal_ParagraphCardQuote: ParagraphCardQuote,
  Drupal_ParagraphImageContent: ParagraphImageContent,
  Drupal_ParagraphForm: ParagraphForm,
  Drupal_ParagraphPhotoGallery: ParagraphPhotoGallery,
  Drupal_ParagraphVideo: ParagraphVideo,
  Drupal_ParagraphCardIcon: ParagraphCardIcon,
  Drupal_ParagraphAccordion: ParagraphAccordion,
  Drupal_ParagraphTimeline: ParagraphTimeline,
  Drupal_ParagraphVignette: ParagraphVignette,
  Drupal_ParagraphColumnsRow: ParagraphColumnsRow,
  Drupal_ParagraphCardImage: ParagraphCardImage,
  Drupal_ParagraphCardContact: ParagraphCardContact,
  Drupal_ParagraphTwitterTimeline: ParagraphTwitterTimeline,
  Drupal_ParagraphAddressLocationBlock: ParagraphAddressLocationBlock,
  Drupal_ParagraphDownloads: ParagraphDownloads,
  Drupal_ParagraphMediaGallery: ParagraphMediaGallery,
  Drupal_ParagraphSocialMedia: ParagraphSocialMedia,
  Drupal_ParagraphView: ParagraphView,
  Drupal_ParagraphAgenda: ParagraphAgenda,
  Drupal_ParagraphIframe: ParagraphIframe,
  Drupal_ParagraphMenu: ParagraphMenu,
  Drupal_ParagraphAnchorMenu: ParagraphAnchorMenu,
  Drupal_ParagraphPlainImage: ParagraphPlainImage,
  Drupal_ParagraphBreadcrumb: ParagraphBreadcrumb
}

export default mappedParagraphs
